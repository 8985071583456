export const getProjectFiltres_rec_ukraine = (local) => {
    return data_eng;
}

const data_eng = [
    {
        title: 'Rotary Area of Focus:',
        bdfield: 'category',
        items: [
            "1. Promoting peace",
            "2. Fighting disease",
            "3. Providing clean water, sanitation, and hygiene",
            "4. Saving mothers and children",
            "5. Supporting education",
            "6. Growing local economies",
            "7. Protecting the environment",
        ],
    },
    {
        title: 'Project category:',
        bdfield: 'category2',
        items: [
            "Mental Health",
            "Internally displaced people",
            "Demining",
            "Medical",
            "Community development",
            "Humanitarian",
            "Children and Education",
            "Others",
        ],
    },
    {
        title: 'Realization Stage:',
        bdfield: 'status',
        items: [
            "Offered, looking for global grant partners",
            "Offered, looking for other partners",
            "Financed and in process of realization", 
            "Permanently active",
            "Succesfuly finished",
        ],
    },
    {
        title: 'Budget:',
        bdfield: 'budget',
        items: [
            "<2000$",
            "2,000-10,000$",
            "10,000-55,000$",
            ">55,000$",
        ],
    },
//     {
//         title: 'Rotary Club Type:',
//         clubtype: [
//             "Rotary",
//             "Rotaract"
//         ],
//     },
];

