import React from 'react';
import { LOCALES } from '../i18n/locales';

export const LocalContext = React.createContext();

export function LanguageContextProvider({ children }) {
  const [currentLocale, setCurrentLocale] = React.useState(getInitialLocale());

  function getInitialLocale() {
    const savedLocale = localStorage.getItem('locale');
    return LOCALES.ENGLISH//savedLocale || LOCALES.UKRAINIAN;
  }

  return (
    <LocalContext.Provider value={{ currentLocale, setCurrentLocale }}>
      {children}
    </LocalContext.Provider>
  );
}

export default LocalContext;
