
import React, { useEffect, useState, useContext, useRef  } from "react";
import { useParams } from 'react-router-dom';
import { LOCALES } from '../../../i18n/locales';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { Box, Typography, Container, Link } from '@mui/material';
import { Card, CardActionArea, CardActions, CardContent, CardMedia, Button } from '@mui/material';
import { Grid } from '@mui/material';
import { MdEmail } from 'react-icons/md';
import PhoneIcon from '@mui/icons-material/Phone';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import { Chart as ChartJS, ArcElement, Tooltip as ChartTooltip, Legend } from "chart.js";
import { Pie } from 'react-chartjs-2';

import {getRProjects} from '../R_ProjectsShowcase/getRProjects';
import {LocalContext} from '../../../tools/LanguageContextProvider';
import LandingFilters from '../fixcomponents/LandingFilters';
import LandingContentItems from '../../../components/LandingContentItems';
import LAccordionItem from '../../../components/LAccordionItem';
import LandingCards2List from '../../../components/LandingCards2List';
import LcButtonPhone from './LcButtonPhone';
import LcButtonEmail from './LcButtonEmail';
import LcButtonFB from './LcButtonFB';
import LcButtonYoutube from './LcButtonYoutube';
import LcButtonPdf from './LcButtonPdf';
import LandingProjectPage from './LandingProjectPage';
import ProjectPhotoCarusel from './ProjectPhotoCarusel';

import { fetchProject } from '../rotariansBD/api/api_projects';
import { fetchProjects } from '../rotariansBD/api/api_projects';
import { createProject} from '../rotariansBD/api/api_projects';
import {hasAnyCategory, calculateProjCount_2} from '../../../modules/ProjectCardGUI/toolsfunc/FilterProjectTools';

ChartJS.register(ArcElement, ChartTooltip, Legend);

function limitString(inputString) {
    const maxChars = 100;
    const maxLines = 6;
  
    // Розділити рядок на масив рядків
    const lines = inputString.split('\n');
  
    let result = '';
  
    for (let i = 0; i < lines.length && i < maxLines; i++) {
      // Змінити поточний рядок, щоб він не перевищував максимальну кількість символів
      const currentLine = lines[i].substring(0, maxChars);
  
      // Додати поточний рядок до результату
      result += currentLine;
  
      // Якщо це не останній рядок і є ще рядки, додайте символ переносу рядка
      if (i < lines.length - 1 && i < maxLines - 1) {
        result += '\n';
      }
    }
  
    return result;
}
const onlydocss = (card) => {
    if (typeof card.docs === 'string') {
        // Розділення строки за комами і видалення пробільних символів
        return card.docs.split(',').map(img => img.trim());
    }
    return null;
};

const LandingChartProjects = ({landItem}) => {
    const tags = landItem.LandingChartProjects;
    
    const {currentLocale, setCurrentLocale} = useContext(LocalContext);
    const [data, setData] = useState([]);
    const [tagedData, setTagedData] = useState([]);
    const [selectedProj, setSelectedProj] = useState(-1);
    const [hoveredRowIndex, setHoveredRowIndex] = useState(null);

    const [cardView, setCardView] = useState(0);   

    const categories = [
        "1. Promoting peace",
        "2. Fighting disease",
        "3. Providing clean water, sanitation, and hygiene",
        "4. Saving mothers and children",
        "5. Supporting education",
        "6. Growing local economies",
        "7. Protecting the environment",
        "-. other"
    ];

    const categories2 = [
        "medicinal",
        "rehabilitation",
        "mental care",
        "education/children",
        "WASH",
        "humanitarian demining",
        "IDPs families",
        "community recovery",
        "generators",
        "logistic costs",
    ];
    const statuses = [
        "Offered, looking for global grant partners",
        "Offered, looking for other partners",
        "In process", 
        "Permanently active",
        "During the war in Ukraine"
    ];

    const budget = [
        "<1000$",
        "1000-2000$",
        "2000-5000$",
        "5000-10,000$",
        "10,000-20,000$",
        "20,000-50,000$",
        ">50,000$"
    ];

    const clubtype = [
        "Rotary",
        "Rotaract"
    ];
    
    const [countCategories, setCountCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState(categories);
    const [selectedStatuses, setSelectedStatuses] = useState(statuses);
    const [selectedRType, setSelectedRType] = useState(clubtype);

    const  designType='chicago';

    useEffect(() => {
      const fetchData = async () => {
        try {
            await loadData(currentLocale);
        } catch (error) {
            console.log("DanatePage get data list error="+error);
        }
      };
      fetchData();
    }, [])

    useEffect(() => {
        loadData(currentLocale);
        console.log("Change curent langufge то ="+currentLocale);
    }, [currentLocale])

    const loadData = async(locale) => {
        const d = await fetchProjects();//getRProjects(locale);
        
        const filteredData = d.filter(item => item.tags === tags);
        setData( filteredData );

        const filteredData2 = filterData(filteredData)
        setTagedData(filteredData2);

        console.log("DanatePage get data list success. const="+d.length);
    }

    useEffect(() => {
        const filteredData2 = filterData(data)
        setTagedData(filteredData2);
        console.log("Change curent filter ("+filteredData2.length+") "+selectedCategories);
    }, [data,selectedCategories, selectedStatuses, selectedRType])

    const filterData = (data) => {
        return data;
    }


    // Порахунок кількості кожного типу в масиві
    const counts = tagedData.reduce((acc, curr) => {
        acc[curr.category] = (acc[curr.category] || 0) + 1;
        return acc;
    }, {});
    const c1 = calculateProjCount_2(tagedData, categories, 'category');
    const counts_ = categories.reduce((acc, category, index) => {
        acc[category] = c1[index];
        return acc;
    }, {});

    const counts2 = tagedData.reduce((acc, curr) => {
        acc[curr.category2] = (acc[curr.category2] || 0) + 1;
        return acc;
    }, {});
    const c2 = calculateProjCount_2(tagedData, categories2, 'category2');
    const counts2_ = categories2.reduce((acc, category2, index) => {
        acc[category2] = c2[index];
        return acc;
    }, {});

    const counts3 = tagedData.reduce((acc, curr) => {
        acc[curr.status] = (acc[curr.status] || 0) + 1;
        return acc;
    }, {});

    const counts4 = tagedData.reduce((acc, curr) => {
        acc[curr.budget] = (acc[curr.budget] || 0) + 1;
        return acc;
    }, {});

    // Підготовка даних для кругової діаграми
    const dataChart = (param) => {
        return {
            labels: Object.keys(param),
            datasets: [{
                data: Object.values(param),
                backgroundColor: [
                    'rgba(255, 99, 132, 0.6)',
                    'rgba(54, 162, 235, 0.6)',
                    'rgba(255, 206, 86, 0.6)',
                    'rgba(75, 192, 192, 0.6)',
                    'rgba(153, 102, 255, 0.6)',
                    'rgba(255, 159, 64, 0.6)',
                    // Додайте інші кольори за потреби
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                    // Додайте інші кольори за потреби
                ],
                borderWidth: 1,
            }],
        }
    };

    return (
        <>
        {/* count={tagedData && tagedData.length}<br/>
        counts={ JSON.stringify(counts)}<br/>
        counts={ JSON.stringify(c1)} */}
        
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
            <div style={{ maxWidth: '340px' }}>
                Area of focus:
            <Pie data={dataChart(counts_)} />
            </div>
            </Grid>

            <Grid item xs={12} md={6}>
            <div style={{ maxWidth: '340px' }}>
                Category:
            <Pie data={dataChart(counts2_)} />
            </div>
            </Grid>

            <Grid item xs={12} md={6}>
            <div style={{ maxWidth: '340px' }}>
                Project status:
            <Pie data={dataChart(counts3)} />
            </div>
            </Grid>

            <Grid item xs={12} md={6}>
            <div style={{ maxWidth: '340px' }}>
                Budget:
            <Pie data={dataChart(counts4)} />
            </div>
            </Grid>
        </Grid>
        </>
  );
}

export default LandingChartProjects;