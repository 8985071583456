import React, { useState, useEffect } from 'react';
import {
    communiqueTableTitles,
    communiqueURLsTableTitles,
    getAllCommuniques,
    getCommuniqueById,
    createCommunique,
    updateCommunique,
    deleteCommunique,
    createURLs,
    updateURLs,
    deleteURLs
} from '../../api/ts/documents_communique';
import { Table, TableBody, TableCell, TableHead, TableRow, Button, Container, Typography } from '@mui/material';
import { Dialog, TextField, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import TableComponent from './TableComponent';
import FormFieldsComponent from './FormFieldsComponent';

function AdminCommunique_old() {
    const [communiques, setCommuniques] = useState([]);
    const [selectedCommunique, setSelectedCommunique] = useState(null);
    const [loading, setLoading] = useState(true);
    const [openUrlDialog, setOpenUrlDialog] = useState(false);
    const [selectedUrls, setSelectedUrls] = useState(null);
    const [selectedUrl, setSelectedUrl] = useState(null);
    const [urlValue, setUrlValue] = useState('');
    
    useEffect(() => {
        async function fetchData() {
            try {
                const data = await getAllCommuniques();
                setCommuniques(data);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching data", error);
                setLoading(false);
            }
        }
        fetchData();
    }, []);

    const handleSubmit = async () => {
        if (selectedCommunique && selectedCommunique.id) {
            await updateCommunique(selectedCommunique);
        } else {
            await createCommunique(selectedCommunique);
        }
        // Refresh data
        const data = await getAllCommuniques();
        setCommuniques(data);
        setSelectedCommunique(null);
    };

    const handleDelete = async (id) => {
        await deleteCommunique(id);
        // Refresh data
        const data = await getAllCommuniques();
        setCommuniques(data);
    };

    const handleSelectUrls = (communique) => {
        console.log("handleSelectUrls");
        setSelectedCommunique(communique);
        setSelectedUrls(communique.urls);
        setOpenUrlDialog(true);
    }

    const handleDeleteUrl = async (id) => {
        await deleteURLs(id);
        // Refresh data
        const data = await getAllCommuniques();
        setCommuniques(data);

        setSelectedCommunique(null);
        setSelectedUrls(null);
        setOpenUrlDialog(false);
    }

    const handleUrlSubmit = async () => {
        if (selectedUrl && selectedUrl.id) {
            await updateURLs(selectedUrl);
        } else {
            await createURLs(selectedUrl);
        }
        // Refresh data
        const data = await getAllCommuniques();
        setCommuniques(data);
        
        setSelectedUrl(null);
        setSelectedCommunique(null);
        setOpenUrlDialog(false);
        
        //handleCloseUrlDialog();
    };

    const handleSelectedUrl = (data) => {
        //setSelectedCommunique(null);
        setSelectedUrl(data);
    }

    const handleOpenUrlDialog = (url, communiqueId) => {
        setSelectedUrls({ url, communiqueId });
        setUrlValue(url.title);
        setOpenUrlDialog(true);
    };
    
    const handleCloseUrlDialog = () => {
        setOpenUrlDialog(false);
        setSelectedUrls(null);
        setUrlValue('');
    };

    const handleAddUrl = (communiqueId) => {
        console.log("handleAddUrl");
        const newUrl = {
            comunique_id: communiqueId,
            title: '',
            url: null,
        }
        setSelectedUrl(newUrl);
        setOpenUrlDialog(true);
    };

    if (loading) return <div>Loading...</div>;

    return (
        <Container>
            <h2>Admin Communique</h2>
            {communiques && 
            <TableComponent 
                communiques={communiques}
                communiqueTableTitles={communiqueTableTitles}
                setSelectedCommunique={setSelectedCommunique}
                handleDelete={handleDelete}
                handleSelectUrls={handleSelectUrls}
                hasChildren={true}
            />}

            <br/>
            
            {selectedCommunique && !openUrlDialog && (
                <>
                <Typography variant="h5">
                    {selectedCommunique && (selectedCommunique.id ? "Edit Communique" : "Add Communique")}
                </Typography>
                <FormFieldsComponent
                    selectedCommunique={selectedCommunique}
                    communiqueTableTitles={communiqueTableTitles}
                    setSelectedCommunique={setSelectedCommunique}
                    handleSubmit={handleSubmit}
                />
                </>
                
            )}
            { (!selectedCommunique && !openUrlDialog) && 
                <Button variant="contained" color="primary" onClick={() => setSelectedCommunique({})}>Add New Communique</Button>
            }

            <br/>
            ==========================================
            {selectedCommunique  && selectedCommunique.urls && 
            <>
                <h2>Admin URLs for comunique id={selectedCommunique.id}</h2>
                <TableComponent 
                    communiques={selectedCommunique.urls}
                    communiqueTableTitles={communiqueURLsTableTitles}
                    setSelectedCommunique={handleSelectedUrl}
                    handleDelete={handleDeleteUrl}
                    handleOpenUrlDialog={handleOpenUrlDialog}
                    hasChildren={false}
                />
                <Button onClick={() => handleAddUrl(selectedCommunique.id)}>Add URL</Button>
                <Button onClick={() => { setSelectedCommunique(null); setOpenUrlDialog(false); }}>Cancel</Button>
                
                {openUrlDialog && selectedUrl &&
                <FormFieldsComponent
                    selectedCommunique={selectedUrl}
                    communiqueTableTitles={communiqueURLsTableTitles}
                    setSelectedCommunique={setSelectedUrl}
                    handleSubmit={handleUrlSubmit}
                />
                }
            </>}

        </Container>
    );
}

export default AdminCommunique_old;
