import React, { useEffect, useState } from "react";
import { Box, Container, Grid, Typography } from '@mui/material'
import './Pages.css';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import GlobeIcon from '@mui/icons-material/Public';

import MainPage from "../components/Main";
import LandingBlock from '../components/LandingBlock';
import LandingHeader from '../components/LandingHeader';
import LAccordionItem from '../components/LAccordionItem';
import { getAll2 } from "../api/ts/api_d2232_events";

import GOOGLEFORM_PNG from '../assets/img/icons/google-form.png';
import GOOGLEFORM_D_PNG from '../assets/img/icons/google-form_d2.png';
import PPT_PNG from '../assets/img/icons/ppt.png';
import PPT_D_PNG from '../assets/img/icons/ppt_d2.png';

const EventsFrame = () => {
    const [data, setData] = useState(null);
    const [pdata, setPData] = useState(null);
    const [fdata, setFData] = useState(null);

    useEffect(() => {
      const fetchData = async () => {
        try {
            const events = await getAll2('01.07.2023', '01.07.2025');
            const sortedEvents = events.sort((a, b) => {
                return parseDateString(a.date).getTime() - parseDateString(b.date).getTime();
            });
            
            console.log('getEventsData = '+JSON.stringify(events.data, null, 2));
            setData(sortedEvents);
            setPData(filterPastEvents(sortedEvents));
            setFData(filterFutureEvents(sortedEvents));
        } catch (error) {
          // Обробити помилку отримання даних
        }
      };

      fetchData();
    }, []);

    const filterPastEvents = (events) => {
        const currentDate = new Date();

        return events.filter(event => {
            const eventDate = parseDateString(event.date);
            // Порівнюємо дати
            return eventDate.getTime() < currentDate.getTime();
        });
    }
    const filterFutureEvents = (events) => {
        const currentDate = new Date();

        return events.filter(event => {
            const eventDate = parseDateString(event.date);
            // Порівнюємо дати
            return eventDate.getTime() >= currentDate.getTime();
        });
    }
    
    function parseDateString(str) {
        const parts = str.split(".");
        const year = parseInt(parts[parts.length - 1], 10);
        const month = parseInt(parts[parts.length - 2], 10) - 1; // місяці в JS рахуються від 0 до 11
        const day = parts.length === 3 ? parseInt(parts[0], 10) : 1; // якщо відсутній день, встановіть його на 1
    
        return new Date(year, month, day);
    }
    function isOldDate(inputDate) {
        const currentDate = new Date();
        // Оскільки ми порівнюємо дати без часової частини, потрібно її "обнулити"
        currentDate.setHours(0, 0, 0, 0);
    
        return parseDateString(inputDate) < currentDate;
    }

    const showTable = (tableData) => {
        return (
            <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow sx={{ backgroundColor: "#98c8ff" }}>
                        <TableCell>Дата</TableCell>
                        <TableCell>Назва заходу</TableCell>
                        <TableCell>Клуб організатор та місце проведення</TableCell>
                        <TableCell>Ресурси</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tableData && tableData.map((row, index) => (
                        <TableRow key={index}>
                            <TableCell style={{ color: isOldDate(row.date) ? 'lightgray' : 'inherit' }}>{row.date}</TableCell>
                            <TableCell style={{ color: isOldDate(row.date) ? 'lightgray' : 'inherit' }}>{row.event}</TableCell>
                            <TableCell style={{ color: isOldDate(row.date) ? 'lightgray' : 'inherit' }}>{row.club}</TableCell>
                            <TableCell>
                                <IconButton 
                                    onClick={() => window.open(row.regLink, '_blank')} 
                                    aria-label="open Google Form in new window"
                                    color="primary"
                                    disabled={!row.regLink || isOldDate(row.date)}
                                >
                                    {isOldDate(row.date) || !row.regLink ? 
                                        <img src={GOOGLEFORM_D_PNG} alt="Google Form" style={{ width: '20px', height: '20px' }} /> :
                                        <img src={GOOGLEFORM_PNG} alt="Google Form" style={{ width: '20px', height: '20px' }} />
                                    }
                                    
                                </IconButton>
                                <IconButton 
                                    onClick={() => window.open(row.siteLink, '_blank')} 
                                    aria-label="open link in new window"
                                    color="primary"
                                    disabled={!row.siteLink}
                                >
                                    <GlobeIcon />
                                </IconButton>
                                <IconButton 
                                    onClick={() => window.open(row.docsLink, '_blank')} 
                                    aria-label="open Google Form in new window"
                                    color="primary"
                                    disabled={!row.docsLink }
                                >
                                    {!row.docsLink ? 
                                        <img src={PPT_D_PNG} alt="Google Form" style={{ width: '24px', height: '24px' }} /> :
                                        <img src={PPT_PNG} alt="Google Form" style={{ width: '24px', height: '24px' }} />
                                    }
                                    
                                </IconButton>
                            </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
        )
    }

    return (
        <>
            <LandingBlock mode='light'>
            <Box className="section text-left" >
                <Container>
                    <LandingHeader><Typography variant="h4" className="page-header">Календар Подій Дистрикту 2232 на 2023-2024 Ротарійський рік</Typography></LandingHeader>
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" >
                    <img src="https://res.cloudinary.com/tyko/image/upload/v1697893520/BetaRotarySite/SitePages/events2_dglsp7.png" height="120px" />
                </Box>
                    <LAccordionItem title="Минулі події">
                        {showTable(pdata)} 
                    </LAccordionItem> 
                    <LAccordionItem title="Майбутні події" defaultExpanded> 
                        {showTable(fdata)}    
                    </LAccordionItem> 
                </Container>
            </Box>
            </LandingBlock>
        </>
  );
}

export default EventsFrame;