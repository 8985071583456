import React, { useEffect, useState, useContext, createContext } from "react";
import { useNavigate } from 'react-router-dom';

import './Navbar.css';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Menu, MenuItem, Divider, IconButton, Drawer, useMediaQuery, useTheme } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { LocalContext } from '../tools/LanguageContextProvider';

import MenuIcon from '@mui/icons-material/Menu';
import { LOCALES } from '../i18n/locales';

import D2232LOGO from '../assets/img/D2232.png';

const Navbar = ({menu}) => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    
    const [scrollPosition, setScrollPosition] = useState(0);
    const {currentLocale, setCurrentLocale} = useContext(LocalContext);
    const [anchorEl0, setAnchorEl0] = useState(null);
    const [anchorEl1, setAnchorEl1] = useState(null);
    const [anchorEl2, setAnchorEl2] = useState(null);
    const [anchorEl3, setAnchorEl3] = useState(null);
    const [anchorEl4, setAnchorEl4] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        setCurrentLocale(LOCALES.UKRAINIAN);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleLanguageClick = () => {
        console.log("Клик по кнопке языка");
        //setCurrentLocale(currentLocale === LOCALES.UKRAINIAN ? LOCALES.ENGLISH : LOCALES.UKRAINIAN);
    };

    const handleLoginClick = () => {
        
    };

    const handleMenuItemClick = (page) => {
        console.log("Клик по MenuItem = "+page);
        navigate(page);
        //window.location.reload();

        /*const currentPath = window.location.pathname;
        const newPath = page;
    
        // Перезавантаження сторінки, якщо посилання активне
        if (currentPath === newPath) {
          window.location.reload();
        } else {
          navigate(newPath);
        }*/
    };

    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    const handleHomeClick = (event) => {
        setAnchorEl0(event.currentTarget);
    };

    const handleAboutRIClick = (event) => {
        setAnchorEl1(event.currentTarget);
    };

    const handleAboutD2232Click = (event) => {
        setAnchorEl2(event.currentTarget);
    };

    const handleForMembersClick = (event) => {
        setAnchorEl3(event.currentTarget);
    };

    const handleMyRotaryUaClick = (event) => {
        setAnchorEl4(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl0(null);
        setAnchorEl1(null);
        setAnchorEl2(null);
        setAnchorEl3(null);
        setAnchorEl4(null);
    };
    
    const toMyRotaryUAClick = () => {
        console.log("toMyRotaryUAClick");
        window.open('/myhome', '_blank');
    }
    const toMeansBusinesClick = () => {
      console.log("toMeansBusinesClick");
      window.open('https://bc.rotary.org.ua', '_blank');
    }
    const toBrandCentrUAClick = () => {
      console.log("toMeansBusinesClick");
      window.open('http://imagetools.tyko.com.ua', '_blank');
    }
    const toExternalLinkClick = (url) => {
        console.log("toMeansBusinesClick");
        window.open(url, '_blank');
    }

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
      };

    const handleLendingItemClick = (id) => {
        navigate('/home', { state: { id } }); // Передача id у стан переходу
        handleClose();
    };
    
    
    const menuItems = (
        <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center' }} >
            <Button className="muiNavbarButton" color="inherit" onClick={handleHomeClick}><FormattedMessage id='menuHome' /></Button>
            <Menu
              anchorEl={anchorEl0}
              keepMounted
              open={Boolean(anchorEl0)}
              onClose={handleClose}
          >
            {menu && menu.map((item) => (
                <MenuItem onClick={() => handleLendingItemClick(item.id)}>
                    <FormattedMessage id={item.header} />
                </MenuItem>
            ))}
              
            </Menu>
            <Button className="muiNavbarButton" color="inherit" onClick={handleAboutRIClick}><FormattedMessage id='menuAboutRI' /></Button>
            <Menu
              anchorEl={anchorEl1}
              keepMounted
              open={Boolean(anchorEl1)}
              onClose={handleClose}
          >
              <MenuItem onClick={() => handleMenuItemClick('/about_ri')}>
                  <FormattedMessage id='Про Ротарі Інтернейшнл' />
              </MenuItem>
              <MenuItem onClick={() => handleMenuItemClick('/leaders_ri')}>
                  <FormattedMessage id='Лідери РІ' />
              </MenuItem>
              <MenuItem onClick={() => handleMenuItemClick('/foundation_ri')}>
                  <FormattedMessage id='Про Фундацію РІ' />
              </MenuItem>
            </Menu>
            <Button className="muiNavbarButton" color="inherit" onClick={handleAboutD2232Click}><FormattedMessage id='menuAboutD2232' /></Button>
            <Menu
              anchorEl={anchorEl2}
              keepMounted
              open={Boolean(anchorEl2)}
              onClose={handleClose}
          >
              <MenuItem onClick={() => handleMenuItemClick('/about_d2232')}>
                  <FormattedMessage id='Про Д2232' />
              </MenuItem>
              <MenuItem onClick={() => handleMenuItemClick('/leaders_d2232')}>
                  <FormattedMessage id='Лідери Д2232' />
              </MenuItem>
              
              <MenuItem disabled onClick={() => handleMenuItemClick('/projects')}>
                  <FormattedMessage id='menuProjects' />
              </MenuItem>
              <MenuItem onClick={() => handleMenuItemClick('/magazines')}>
                  <FormattedMessage id='Журнали "Ротарієць"' />
              </MenuItem>
              <MenuItem onClick={() => handleMenuItemClick('/events')}>
                  <FormattedMessage id='menuEvents' />
              </MenuItem>
              <MenuItem onClick={() => handleMenuItemClick('/clubs')}>
                  <FormattedMessage id='Клуби' />
              </MenuItem>
              {/* <MenuItem onClick={() => handleMenuItemClick('/rotaryclubs')}>
                  <FormattedMessage id='Ротарі Клуби' />
              </MenuItem>
              <MenuItem onClick={() => handleMenuItemClick('/rotaractclubs')}>
                  <FormattedMessage id='Ротаракт Клуби' />
              </MenuItem>
              <MenuItem onClick={() => handleMenuItemClick('/sateliteclubs')}>
                  <FormattedMessage id='Сателітні Клуби' />
              </MenuItem> 
              <MenuItem onClick={() => handleMenuItemClick('/interactclubs')}>
                  <FormattedMessage id='Інтеракт Клуби' />
              </MenuItem>*/}
              <MenuItem onClick={() => handleMenuItemClick('/icc')}>
                  <FormattedMessage id='Міждержавні комітети' />
              </MenuItem>
              <MenuItem onClick={() => handleMenuItemClick('/fellowships')}>
                  <FormattedMessage id='Товариства' />
              </MenuItem>
            </Menu>

            <Button className="muiNavbarButton" color="inherit" onClick={toMeansBusinesClick}><FormattedMessage id='Для Бізнесу' /></Button>
            <Button className="muiNavbarButton" color="inherit" onClick={handleForMembersClick}><FormattedMessage id='Зовнішні ресурси' /></Button>
            <Menu
                anchorEl={anchorEl3}
                keepMounted
                open={Boolean(anchorEl3)}
                onClose={handleClose}
            >
                <MenuItem onClick={() => toExternalLinkClick('/myhome')}>
                    <FormattedMessage id='Внутрішня сторінка Дистрикту' />
                </MenuItem>
                <MenuItem onClick={() => toExternalLinkClick('https://www.facebook.com/RotaryInUkraine')}>
                    <FormattedMessage id='menuNews' />
                </MenuItem>
                <MenuItem disabled onClick={() => handleMenuItemClick('/brandcenter')}>
                    <FormattedMessage id='Бренд Центр' />
                </MenuItem>
                {/*<MenuItem onClick={toMeansBusinesClick}>
                    <FormattedMessage id='Business Council UA' />
                </MenuItem>*/}
                <MenuItem onClick={() => toExternalLinkClick('https://my.rotary.org/en/club-search')}>
                    <FormattedMessage id='Пошук клубів в РІ' />
                </MenuItem>
            </Menu>
        </Box >
    )

    const navBarStyle = {
        transition: '0.1s', // add transition
        _height: scrollPosition > 0 ? '60px' : '110px',
        backgroundColor: '#ffffff', 
        color: '#000080',
    };

    return (
        <AppBar position="sticky" sx={navBarStyle}>
            <Toolbar>
            <Box sx={{ display: 'flex', alignItems: "center", gap:'18px', width: '100%'}}>
                <Box sx={{ display: 'flex', alignItems: 'left', height: "60px"}}>
                    <img src={D2232LOGO} style={{padding: "8px", marginLeft: "0px"}}  alt="Логотип" />
                </Box>
                <Box className="text-container">
                <Box><span className="title"><FormattedMessage id='menuTitle' /></span></Box>
                {isMobile ? (
                  <>
                    <IconButton edge="end" color="inherit" aria-label="menu" onClick={handleDrawerToggle}>
                      <MenuIcon />
                    </IconButton>
                    <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerToggle}>
                    <Box sx={{ display: 'flex', flexDirection: 'column'}}>
                      {menuItems}
                    </Box>
                    </Drawer>
                  </>
                ) : (
                  menuItems
                )}
                </Box>
                <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }}>
                    <Button size="small" color="inherit" onClick={() => handleMenuItemClick('/donate')}><FormattedMessage id='menuDonate' /></Button>
                    <Button size="small" color="inherit" onClick={() => toExternalLinkClick('https://my.rotary.org/en/club-search')}><FormattedMessage id='Пошук' /></Button>
                    <Button disabled size="small" color="inherit" onClick={handleLanguageClick}>{currentLocale}</Button>
                </Box>
            </Box>
            </Toolbar>
        </AppBar>
    );
};

export default Navbar;

