import React from 'react';
import JsonEditor from './JsonEditor';
import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Button, Typography } from '@mui/material';
import { FormControl, InputLabel, MenuItem, Select, FormControlLabel, Checkbox } from '@mui/material';

import { changeMetafieldLine, getSubfieldValue } from '../../../../modules/CRUD_system/functions/MetafieldEditLineTools';

//======================================
// use in rc_eclubofukraine admin page
//======================================
const AdminDialogCrud_SitePage = ({ open, onClose, onSubmit, isEditMode, columns, currentItem, currentItem2, setCurrentItem, filters }) => {
    
    const onChangeAvLine = (e, currentItem, column) => {
        console.log(`onChangeAvLine...`);
        const [field, subfield] = column.field.split(':');
        const advVal = changeMetafieldLine(e, currentItem, column);
        console.log(`advVal### = ${JSON.stringify(currentItem)}`);
        setCurrentItem({ ...currentItem, [field]: advVal });
    }

    
    const onChangeCheckBox = (e, column, category) => {
        const isChecked = e.target.checked;
        let updatedItems;
    
        // Перевірка, чи поле column.field є рядком JSON
        if (typeof currentItem[column.field] === 'string' && currentItem[column.field].trim() !== '') {

            try {
            const parsedItems = JSON.parse(currentItem[column.field]);
            
            // Перевірка, чи вже вибрано елемент
            if (isChecked && !parsedItems.includes(category)) {
                updatedItems = [...parsedItems, category];
            } else if (!isChecked && parsedItems.includes(category)) {
                updatedItems = parsedItems.filter((item) => item !== category);
            } else {
                // Якщо вибір не змінився, повертаємо поточний стан без змін
                updatedItems = parsedItems;
            }
            } catch (error) {
                updatedItems = isChecked ? [category] : [];
                //throw error;
            }
        } else {
            updatedItems = isChecked ? [category] : [];
        }
    
        // Перетворення масиву в строку JSON та оновлення стану
        setCurrentItem({ ...currentItem, [column.field]: JSON.stringify(updatedItems) });
    }

    const show_columns = (data_columns, data_item) => {

        return (
            <>
            {data_columns && data_columns.map((column) => {
                    //if (column.type === 'lineedit' || column.type === 'multilineedit' || column.type === 'img') {
                        switch (column.type) {
                            case 'lineedit':
                            case 'multilineedit':
                            case 'img':
                                return (
                                    <TextField
                                        key={column.field}
                                        autoFocus
                                        margin="normal"
                                        id={column.field}
                                        label={column.headerName}
                                        type={column.type === 'multilineedit' ? 'text' : column.field}
                                        fullWidth
                                        variant="outlined"
                                        value={data_item[column.field]}
                                        onChange={(e) => setCurrentItem({ ...data_item, [column.field]: e.target.value })}
                                        multiline={column.type === 'multilineedit'}
                                        minRows={column.type === 'multilineedit' ? 3 : 1}
                                    />
                                )

                            case 'combo':
                                return (
                                    <FormControl fullWidth sx={{ marginTop: '8px' }}>
                                        <Typography variant="subtitle1" sx={{ marginBottom: '4px' }}>{getTitleByBdfield(filters, column.field)}</Typography>
                                        <Select
                                        id={data_item[column.field]}
                                        value={data_item[column.field]}
                                        //onChange={handleCategoryChange}
                                        onChange={(e) => setCurrentItem({ ...data_item, [column.field]: e.target.value })}
                                        >
                                        {getItemsByBdfield(filters, column.field)?.map((category, index) => (
                                            <MenuItem key={index} value={category}>{category}</MenuItem>
                                        ))}
                                        </Select>
                                    </FormControl >
                                )
                            case 'checkbox':
                                return (
                                    <FormControl fullWidth sx={{ marginTop: '8px' }}>
                                    <Typography variant="subtitle1" sx={{ marginBottom: '4px' }}>{getTitleByBdfield(filters, column.field)}</Typography>
                                    {getItemsByBdfield(filters, column.field)?.map((category, index) => (
                                        <FormControlLabel
                                        key={index}
                                        control={
                                            <Checkbox
                                            checked={data_item[column.field].includes(category)}
                                            onChange={(e) => onChangeCheckBox(e, column, category)}
                                            />
                                        }
                                        label={category}
                                        />
                                    ))}
                                    </FormControl>
                                );    
                            case 'advlineedit':
                            case 'advmultilineedit':
                                return (
                                    <TextField
                                        key={column.field}
                                        autoFocus
                                        margin="normal"
                                        id={column.field}
                                        label={column.headerName}
                                        type={(column.type === 'multilineedit' || column.type === 'advmultilineedit') ? 'text' : column.field}
                                        fullWidth
                                        variant="outlined"
                                        value={getSubfieldValue(data_item, column.field)}
                                        onChange={(e) => onChangeAvLine(e, data_item, column)}
                                        multiline={(column.type === 'multilineedit' || column.type === 'advmultilineedit')}
                                        minRows={(column.type === 'multilineedit' || column.type === 'advmultilineedit') ? 3 : 1}
                                    />
                                )
                            
                            case 'json':
                                return (
                                    // <TextField
                                    //     key={column.field}
                                    //     autoFocus
                                    //     margin="normal"
                                    //     id={column.field}
                                    //     label={column.headerName}
                                    //     type={'text'}
                                    //     fullWidth
                                    //     variant="outlined"
                                    //     value={JSON.stringify( data_item?data_item:[] ) }
                                    //     onChange={(e) => onChangeAvLine(e, data_item, column)}
                                    //     multiline={true}
                                    //     minRows={(column.type === 'multilineedit' || column.type === 'advmultilineedit') ? 3 : 1}
                                    // />
                                    <JsonEditor 
                                        data={data_item?data_item:[]}
                                        theme="monokai"
                                    />
                                )

                            default:
                                return <></> 
                        }
                    //}
                    return null;
                })}
                </>
        )
    }

    return (
        <Dialog open={open} onClose={onClose} fullWidth
                maxWidth="lg">

            <DialogTitle>
                <Typography variant="h3" style={{ fontWeight: 'bold', color: 'darkblue' }}>
                    {isEditMode ? 'Edit Item' : 'Add Item'}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Typography variant="h5" style={{ fontWeight: 'bold', _textDecoration: 'underline' }}>
                        {isEditMode ? 'Update the item details.' : 'Enter the details of the new item.'}
                    </Typography>
                </DialogContentText>
                <Box display="flex" flexDirection='row' width='100%' gap={1} >
                    <Box display="flex" flexDirection='column' width='100%' gap={1} >
                        Дефолтна мова
                        {show_columns(columns, currentItem2)}
                    </Box>
                    <Box display="flex" flexDirection='column' width='100%' gap={1} >
                        Мова що редагується
                        {show_columns(columns, currentItem)}
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={onSubmit}>{isEditMode ? 'Update' : 'Add'}</Button>
            </DialogActions>
        </Dialog>
    );

    function getItemsByBdfield(data, bdfield) {
        if (data && bdfield) {
            // Шукаємо об'єкт з відповідним bdfield
            const category = data.find(item => item.bdfield === bdfield);
            // Перевіряємо, чи такий об'єкт був знайдений
            if (category) {
                // Повертаємо елементи, якщо такий bdfield був знайдений
                return category.items;
            } else {
                // Якщо не знайдено, повертаємо порожній масив
                return null;
            }
        } else return null;
    }
    
    function getTitleByBdfield(data, bdfield) {
        if (data && bdfield) {
            // Шукаємо об'єкт з відповідним bdfield
            const category = data.find(item => item.bdfield === bdfield);
            // Перевіряємо, чи такий об'єкт був знайдений
            if (category) {
                // Повертаємо елементи, якщо такий bdfield був знайдений
                return category.title;
            } else {
                // Якщо не знайдено, повертаємо порожній масив
                return null;
            }
        } else return null;
    }
};

export default AdminDialogCrud_SitePage;
