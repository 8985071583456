export const getFindocs = () => {
    return defaultData;
};
export const getFindocs2 = () => {
    return defaultData2;
};
export const getBils = () => {
    return defaultBils;
};

const defaultData2 = [
    {
        "name": "Реквізити для договорів та звітів",
        "title": "",
        "url": "https://docs.google.com/document/d/1jAo_e7gcaMqA2UIfEJTbIFvYbRIGUHw0/edit?usp=sharing&ouid=116503399666831052031&rtpof=true&sd=true"
    },
    {
        "name": "Рішення про статус неприбутковості",
        "title": "",
        "url": "https://drive.google.com/file/d/16NC0Ub0gHQRxVj6lrXXGjj2Cx6lJqbJR/view?usp=sharing"
    },
    {
        "name": "Виписка з Єдиного державного реєстру юридичних осіб",
        "title": "",
        "url": "https://drive.google.com/file/d/15t1eRGb-G4N64X20VUkaddQ-BQORtvHp/view?usp=sharing"
    },
    {
        "name": "Статут Громадської Спілки ",
        "title": "",
        "url": "https://drive.google.com/file/d/1op8nrF8zPL3cajj7oh6Ql1_CuambVFkE/view?usp=sharing"
    },
    {
        "name": "Правила спілки",
        "title": "",
        "url": "https://drive.google.com/file/d/1yP4r2jclENzewW1B13jzrneHk7K4s7m8/view?usp=sharing"
    },
]

const defaultData = [
    {
        "name": "",
        "title": "Пояснення по використанню Актів передачі-прийняття гум. допомоги",
        "url": "https://docs.google.com/document/d/1RyQvcy6NUs46SY_ThyUEjE7Zdc5zGw36/edit?usp=sharing&ouid=116503399666831052031&rtpof=true&sd=true"
    },
    {
        "name": "№0",
        "title": "Приамбула реквізити Договори поставки",
        "url": "https://docs.google.com/document/d/1930DG9zXq74Q8tJmFDy0TMQ7i8eNaLR-/edit?usp=sharing&ouid=116503399666831052031&rtpof=true&sd=true"
    },
    {
        "name": "№1",
        "title": "Накладна (видаткова) ГО коли багато найменувань",
        "url": "https://docs.google.com/document/d/18Vtcyuy5VMHUcIXYb5ZiFCx7FbxoJ69l/edit?usp=sharing&ouid=116503399666831052031&rtpof=true&sd=true"
    },
    {
        "name": "№2",
        "title": "Накладна (видаткова) ГО коли мало найменувань 2 екз",
        "url": "https://docs.google.com/document/d/1HUjGvd4dgv3uYbMXLCa7eYUBCICWJgYR/edit?usp=sharing&ouid=116503399666831052031&rtpof=true&sd=true"
    },
    {
        "name": "№3",
        "title": "Накладна (на прихід) СПІЛКА",
        "url": "https://docs.google.com/document/d/1z675FeeFYLlhGsrfjEF-QfkEY1jd7J6v/edit?usp=sharing&ouid=116503399666831052031&rtpof=true&sd=true"
    },
    {
        "name": "№4",
        "title": "Рахунок ( на прихід) ФОП СПІЛКА",
        "url": "https://docs.google.com/document/d/1Z913eZ3-b-iTIfIBAkZFyCDPoe07VaFT/edit?usp=sharing&ouid=116503399666831052031&rtpof=true&sd=true"
    },
    {
        "name": "№5",
        "title": "Договір ТОВ-СПІЛКА товар",
        "url": "https://docs.google.com/document/d/1uNuZHYd8mKKOqz_RrLbLp0MFyBU9WHXW/edit?usp=sharing&ouid=116503399666831052031&rtpof=true&sd=true"
    },
    {
        "name": "№5.1",
        "title": "Договір ТОВ-СПІЛКА_товар_ 1-а стор",
        "url": "https://docs.google.com/document/d/1WcjYTlA8xirZdfwjnN8W8xQaMY3yHL9P/edit?usp=sharing&ouid=116503399666831052031&rtpof=true&sd=true"
    },
    {
        "name": "№5.2",
        "title": "Договір ТОВ-СПІЛКА_товар_ 2-і стор",
        "url": "https://docs.google.com/document/d/1ZnuBbXuGXHyaC6KMlOcaNDeFVv6Z8H9B/edit?usp=sharing&ouid=116503399666831052031&rtpof=true&sd=true"
    },    
    {
        "name": "№6",
        "title": "Договір ФОП- СПІЛКА_товари",
        "url": "https://docs.google.com/document/d/1t6pW3RcHg64bWCsz5CDHImY_ojNXZKrR/edit?usp=sharing&ouid=116503399666831052031&rtpof=true&sd=true"
    },    
    {
        "name": "№6.1",
        "title": "Договір ФОП- СПІЛКА_товари Продавець_Покупець_2-і стор.",
        "url": "https://docs.google.com/document/d/1AkoICg8-4GaimzJlR8lXJzi48oMDttCK/edit?usp=sharing&ouid=116503399666831052031&rtpof=true&sd=true"
    },    
    {
        "name": "№6.2",
        "title": "Договір ФОП- СПІЛКА_товари Виконав._Замовник_4-и стор.",
        "url": "https://docs.google.com/document/d/1kXrjn8H1kci5Ey9s3eUnCTZBrix7JfNI/edit?usp=sharing&ouid=116503399666831052031&rtpof=true&sd=true"
    },    
    {
        "name": "№6.3",
        "title": "Договір ФОП- СПІЛКА_товари Постач._Покупець_6-ть стор.",
        "url": "https://docs.google.com/document/d/1l5bjPS6MFbSfralj3ijL8wvkzbciXxp2/edit?usp=sharing&ouid=116503399666831052031&rtpof=true&sd=true"
    },    
    {
        "name": "№6.4",
        "title": "Договір ФОП- СПІЛКА_роботи",
        "url": "https://docs.google.com/document/d/1e0BW8FPOyySxWF76pHGE8-7CxpvAVpqm/edit?usp=sharing&ouid=116503399666831052031&rtpof=true&sd=true"
    },    
    {
        "name": "№6.5",
        "title": "Договір ФОП- СПІЛКА_роботи",
        "url": "https://docs.google.com/document/d/1Fm4GS1D-9xnzahU6IWCgFrHuBU2qAQwD/edit?usp=sharing&ouid=116503399666831052031&rtpof=true&sd=true"
    },    
    {
        "name": "№6.6",
        "title": "Договір ФОП- СПІЛКА_послуги",
        "url": "https://docs.google.com/document/d/1h693-37ss7NlQOFPflKfR1XM0FCKKVN4/edit?usp=sharing&ouid=116503399666831052031&rtpof=true&sd=true"
    },    
    {
        "name": "№7",
        "title": "Акт здачі-прийняття робіт(послуг) Спілка Дистр. фонд",
        "url": "https://docs.google.com/document/d/1r8v6KL4LdsMvn2VdMF1DEKzspe837VCT/edit?usp=sharing&ouid=116503399666831052031&rtpof=true&sd=true"
    },    
    {
        "name": "№8",
        "title": "ДОГОВІР БЕЗПОВОРОТНОЇ ФІН. ДОПОМОГИ Київ",
        "url": "https://docs.google.com/document/d/1PsEla2b0Z5O3GPWBNyV75vLTgiVLWYez/edit?usp=sharing&ouid=116503399666831052031&rtpof=true&sd=true"
    },
    {
        "name": "№8.1",
        "title": "ДОГОВІР БЕЗПОВОРОТНОЇ ФІН. ДОПОМОГИ Харків",
        "url": "https://docs.google.com/document/d/1R2AICdKmHJVM2rp8M6v-ausPdp2yHV0U/edit?usp=sharing&ouid=116503399666831052031&rtpof=true&sd=true"
    },  
    {
        "name": "№8.2",
        "title": "ДОГОВІР БЕЗПОВОРОТНОЇ ФІН.ДОП. Харків",
        "url": "https://docs.google.com/document/d/1bCu33_cbrh4jpWo-Q-spWYP0Ibuv8z_b/edit?usp=sharing&ouid=116503399666831052031&rtpof=true&sd=true"
    },  
    {
        "name": "№9",
        "title": "перевезення ДОГОВІР БЕЗПОВОРОТНОЇ ФІН. ДОПОМОГИ",
        "url": "https://docs.google.com/document/d/1MhG2IwekDNKqMak3qcR0Th0ecF79HbQ-/edit?usp=sharing&ouid=116503399666831052031&rtpof=true&sd=true"
    },  
    {
        "name": "№9.1",
        "title": "перевезення ДОГОВІР БЕЗПОВОРОТНОЇ ФІН.ДОП.Дніпр",
        "url": "https://docs.google.com/document/d/1Uz664IRtCELx2Z8PDk-mWs1ChkqYdLcz/edit?usp=sharing&ouid=116503399666831052031&rtpof=true&sd=true"
    },
    {
        "name": "№10",
        "title": "ДОГОВІР фін. доп.кошти Дистриктного фонду",
        "url": "https://docs.google.com/document/d/1oauijYbTbAh8FKgTOl22SmPghNeWHbD3/edit?usp=sharing&ouid=116503399666831052031&rtpof=true&sd=true"
    },

    {
        "name": "№11",
        "title": "Акт здачі-прийняття благодійної доп.Спілка - затверджує ГПС",
        "url": "https://docs.google.com/document/d/1yJtP-iZMwrOitZD0bM2mnMfacFjeYgOv/edit?usp=sharing&ouid=116503399666831052031&rtpof=true&sd=true"
    },
    {
        "name": "№12",
        "title": "Акт здачі-прийняття гумдопомоги Спілка - затверджує ГПС",
        "url": "https://docs.google.com/document/d/1NQbMLe3THp-vWs4hiTt6asGKTgdcfKuE/edit?usp=sharing&ouid=116503399666831052031&rtpof=true&sd=true"
    },
    {
        "name": "№13",
        "title": "Акт передачі благодійної доп. комісія Спілка - затверджує ГПС - копия",
        "url": "https://docs.google.com/document/d/1yHByMSGK-9HOHFnA2-OWgV-zFDOUD-A5/edit?usp=sharing&ouid=116503399666831052031&rtpof=true&sd=true"
    },
    {
        "name": "№14",
        "title": "Акт передачі гумдопомоги комісія Спілка - затверджує ГПС",
        "url": "https://docs.google.com/document/d/17swAEOsxEYqXp3ggdfhoHHYSUigQIDus/edit?usp=sharing&ouid=116503399666831052031&rtpof=true&sd=true"
    },
    {
        "name": "№15",
        "title": "Акт передачі-прийняття гумдопомоги Спілка - затверджує ГПС",
        "url": "https://docs.google.com/document/d/11Mw4QPdP8U9NwklS8IXoIWFvSaeOs_SI/edit?usp=sharing&ouid=116503399666831052031&rtpof=true&sd=true"
    },
    {
        "name": "№16",
        "title": "Акт передачі-прийняття гумдопомоги Спілка - затверджує ГПС з ціною",
        "url": "https://docs.google.com/document/d/1MgYGxnG7XtYkETsxgffgLGVGIiU-8LxJ/edit?usp=sharing&ouid=116503399666831052031&rtpof=true&sd=true"
    },
    {
        "name": "№17",
        "title": "Акт здачі-прийняття благодійної доп. РК- затверджує ГПК",
        "url": "https://docs.google.com/document/d/1WLiR4JamyblS3GWLcZlu3xXXw5-4Nk0E/edit?usp=sharing&ouid=116503399666831052031&rtpof=true&sd=true"
    },
    {
        "name": "№18",
        "title": "Довіреність РОТАРІ  КЛУБ",
        "url": "https://docs.google.com/document/d/1dggdbc0mTvonsa8oEBpIp7Cd3WdoEQ3p/edit?usp=sharing&ouid=116503399666831052031&rtpof=true&sd=true"
    },
    {
        "name": "№19",
        "title": "Довіреність СПІЛКА",
        "url": "https://docs.google.com/document/d/1zuVJJjMFWXs2Whl7luVBMnw2f0Jg0a-P/edit?usp=sharing&ouid=116503399666831052031&rtpof=true&sd=true"
    },
    {
        "name": "№20",
        "title": "Звернення про надання безповоротної допомоги РК",
        "url": "https://docs.google.com/document/d/1AFrgatB3CgKy-BSCXbiSg1TyTrQ1Q3Hw/edit?usp=sharing&ouid=116503399666831052031&rtpof=true&sd=true"
    },
    {
        "name": "№21",
        "title": "Звернення про надання безповоротної допомоги РК перевезення",
        "url": "https://docs.google.com/document/d/1K3cMbFj_d3FmU-k8RviJFvoG8dOj-Qfo/edit?usp=sharing&ouid=116503399666831052031&rtpof=true&sd=true"
    },
    {
        "name": "№22",
        "title": "Звернення про надання безповоротної доп. РК перевезення по маршруту гум.доп",
        "url": "https://docs.google.com/document/d/11po3DpUKg_9wzGNL8sBp2lEn1fxClhuJ/edit?usp=sharing&ouid=116503399666831052031&rtpof=true&sd=true"
    },
    {
        "name": "№23",
        "title": "Звернення про надання безповоротної доп. РК перевезення по маршрути",
        "url": "https://docs.google.com/document/d/1aNITSnQdwab_DIM9T2vqNVN5N4e39BA4/edit?usp=sharing&ouid=116503399666831052031&rtpof=true&sd=true"
    }
]

const defaultBils = [
    {
        "currency:": "UAH",
        "data": "Beneficiary Bank: АТ 'Райффайзен Банк'\n" + 
        "Beneficiary: PA RI DISTRICT 2232 \n" +
        "EDRPOU code: 38871769 " +
        "Account number/IBAN: " +
        ""+
        "UA36 3808 0500 0000 0026 0018 4384 4   ",
    },
    {
        "currency:": "USD",
        "data": "IBAN: UA633808050000000026009627608" +
        ""+
        "SWIFT code: AVALUAUKXXX" +
        "Correspondent Account: 890-0260-688" +
        "CORRESPONDENT BANK: The Bank of New York Mellon, New York, USA" +
        "SWIFT code: IRVTUS3N",
    },
    {
        "currency:": "EURO",
        "data": "IBAN: UA173808050000000026002771409" +
        ""+
        "SWIFT code: AVALUAUKXXX" +
        "Correspondent Account: 55.022.305" +
        "CORRESPONDENT BANK: Raiffeisen Bank International AG" +
        "SWIFT code: RZBAAT WW",
    },
    {
        "currency:": "GBP",
        "data": "ACCOUNT STERLING (GBP) " +
        "Account Number IBAN: UA05 3808 0500 0000 0026 0057 7542 4 " +
        "NAME OF ORGANIZATION: PA RI DISTRICT 2232 " +
        "LEGAL ADDRESS: 04050, UKRAINE, Kyiv city, Gercena str, 17-25, 1 " +
        "BANK OF BENEFICIARY: " +
        "Raiffeisen Bank Joint Stock Company, Leskova street.9, KYIV, 01011, Ukraine " +
        "SWIFT code: AVALUAUKXXX Correspondent Account: 83301931 CORRESPONDENT BANK Barclays Bank PLC " +
        "SWIFT code: BARCGB22   ", 
    },
    {
        "currency": "CHF",
        "data": "Account Number IBAN: UA79 3808 0500 0000 0026 0067 7542 3 " +
        "NAME OF ORGANIZATION: PA RI DISTRICT 2232 " +
        "LEGAL ADDRESS: 04050, UKRAINE, Kyiv city, Gercena str, 17-25, 1 " +
        "BANK OF BENEFICIARY: " +
        "Raiffeisen Bank Joint Stock Company, Leskova street.9, KYIV, 01011, Ukraine " +
        "SWIFT code: AVALUAUKXXX " +
        "Correspondent Account: 0230-69171.05M " +
        "CORRESPONDENT BANK " +
        "UBS Switzerland AG, Zurich " +
        "SWIFT code: UBSWCHZH80A",
    },
    {
        "currency:": "SEK",
        "data": "BENEFICIARY"+
        "Account number/IBAN UA26 3808 0500 0000 0026 0018 3451 8 "+
        "Account currency SEK "+
        "Beneficiary PA RI DISTRICT 2232 "+
        "Legal address of the organization 04050, UKRAINE, Kyiv city, Gercena str, 17-25,"+
        "apartment/office 1"+
        ""+
        "BENEFICIARY BANK"+
        "RAIFFEISEN BANK JOINT STOCK COMPANY,"+
        "KIEV, UKRAINE "+
        "SWIFTcode:"+
        "AVALUAUKXXX "+
        "Correspondent Account:"+
        "81-55.022.305  "+
        ""+
        "CORRESPONDENT BANK "+
        "Use the settlement instructions below:"+
        "RAIFFEISEN BANK INTERNATIONAL AG,"+
        "VIENNA, AUSTRIA "+
        "SWIFTcode: RZBAATWWXXX "
    },
    {
        "currency:": "CAD",
        "data": "BENEFICIARY "+
        "Account number/IBAN UA78 3808 0500 0000 0026 0057 8526 5 "+
        "Account currency CAD "+
        "Beneficiary PA RI DISTRICT 2232 "+
        "Legal address of the organization 04050, UKRAINE, Kyiv city, Gercena str, 17-25,"+
        "apartment/office 1  "+
        ""+
        "BENEFICIARY BANK"+
        "RAIFFEISEN BANK JOINT STOCK COMPANY,"+
        "KIEV, UKRAINE "+
        "SWIFTcode:"+
        "AVALUAUKXXX "+
        "Correspondent Account:"+
        "71-55.022.305   "+
        ""+
        "CORRESPONDENT BANK "+
        "Use the settlement instructions below:"+
        "RAIFFEISEN BANK INTERNATIONAL AG,"+
        "VIENNA, AUSTRIA "+
        "SWIFTcode: RZBAATWWXXX  "
    }
]