import React, { useState } from 'react';
import { Box } from '@mui/material';
import './JsonEditor.css';
import LandingContent from '../../components/LandingContent';

const JsonEditor = ({ data }) => {
  const [json, setJson] = useState(data);
  const [text, setText] = useState(JSON.stringify(json, null, 2));
  const [error, setError] = useState(null);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [editorWidth, setEditorWidth] = useState(50); // width in percentage

  const handleChange = (e) => {
    setText(e.target.value);
    try {
      const parsedJson = JSON.parse(e.target.value);
      setJson(parsedJson);
      setError(null);
    } catch (err) {
      setError('Invalid JSON');
    }
  };

  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };

  const handleMouseDown = () => {
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseMove = (e) => {
    const newWidth = (e.clientX / window.innerWidth) * 100;
    if (newWidth > 5 && newWidth < 95) {
      setEditorWidth(newWidth);
    }
  };

  const handleMouseUp = () => {
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  return (
    <div className={isFullscreen ? 'fullscreen' : ''}>
      <button onClick={toggleFullscreen}>
        {isFullscreen ? 'Exit Fullscreen' : 'Go Fullscreen'}
      </button>
      <Box display="flex" flexDirection="row" width="100%" height="100%" gap={1}>
        <Box
          flexGrow={1}
          style={{ width: `${editorWidth}%`, transition: 'width 0.2s' }}
        >
          <textarea
            value={text}
            onChange={handleChange}
            rows="20"
            cols="50"
            style={{ width: '100%', height: isFullscreen ? '80vh' : '400px' }}
          />
          {error && <div className="error-message">{error}</div>}
        </Box>
        <div
          className="splitter"
          onMouseDown={handleMouseDown}
          style={{ width: '5px', cursor: 'col-resize', backgroundColor: '#ccc' }}
        />
        <Box flexGrow={1} style={{ width: `${100 - editorWidth}%`, transition: 'width 0.2s' }}>
          {isFullscreen && <LandingContent data={json?.page_json} />}
        </Box>
      </Box>
    </div>
  );
};

export default JsonEditor;
