import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Typography, Paper, Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Box, Checkbox, FormControlLabel } from '@mui/material';

import { fetchRClubs, createRClub, updateRClub, deleteRClub } from '../api/api_rclubs';

import CrudTable from './CrudTable';
import AdminDialogCrud from './AdminDialogCrud';
//import RClubSlilsCRUD from './RClubSlilsCRUD';
import {translateToEng} from '../../../ToolsPages/TranslateComponentText';
import {rotaryClubsFromRiBd} from '../../../ToolsPages/rotaryClubsFromRiBd';

const rclubColumns = [
    { field: 'clubID', headerName: 'id', type: 'id', default: '' },
    { field: 't_clubId', headerName: 't_clubId', type: 'lineedit', default: '' },
    { field: 'clubname', headerName: 'clubname', type: 'lineedit', default: '' },
    { field: 't_clubname', headerName: 't_clubname', type: 'lineedit', default: '' },
    { field: 'clubcat', headerName: 'clubcat', type: 'lineedit', default: '' },
    //{ field: 'clubdesc', headerName: 'clubdesc', type: 'lineedit', default: '' },
    //{ field: 'clubcontent', headerName: 'clubcontent', type: 'lineedit', default: '' },
    //{ field: 'clubemail', headerName: 'clubemail', type: 'lineedit', default: '' },
    //{ field: 'clubfacebook', headerName: 'clubfacebook', type: 'lineedit', default: '' },
    { field: 'clubpresident', headerName: 'clubpresident', type: 'lineedit', default: '' },
    { field: 'clubpphone', headerName: 'clubpphone', type: 'lineedit', default: '' },
    //{ field: 'clubplace', headerName: 'clubplace', type: 'lineedit', default: '' },
    { field: 'clubcity', headerName: 'clubcity', type: 'lineedit', default: '' },
    //{ field: 'clubimg', headerName: 'clubimg', type: 'lineedit', default: '' },
    //{ field: 'clubexe', headerName: 'clubexe', type: 'lineedit', default: '' },
    { field: 'clublang', headerName: 'clublang', type: 'lineedit', default: '' },
    { field: 't_riClubId', headerName: 't_riClubId', type: 'lineedit', default: '' },
    { field: 't_memberCount', headerName: 't_memberCount', type: 'lineedit', default: '' },
    { field: 't_charterDate', headerName: 't_charterDate', type: 'lineedit', default: '' },
];

const rclubColumns_ri = [
    { field: 'club', headerName: 'club', type: 'id', default: '' },
    { field: 'clubid', headerName: 'clubid', type: 'lineedit', default: '' },
    { field: 'Current Member Count', headerName: 'Current Member Count', type: 'lineedit', default: '' },
    { field: 'Charter Date', headerName: 'Charter Date', type: 'lineedit', default: '' },
];

const generateDefaultObject = (columns) => {
    return columns.reduce((acc, column) => {
        acc[column.field] = column.default;
        return acc;
    }, {});
};

function sortByClubName(array) {
    return array.sort((a, b) => {
        // Перетворюємо назви клубів у нижній регістр для реєстронезалежного порівняння
        const nameA = a.clubname.toLowerCase();
        const nameB = b.clubname.toLowerCase();

        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0; // Якщо назви однакові
    });
}

function mergeArrays(rclubs_, riClubs_) {
    const mergedClubs = [];
  
    // Перебираємо масив rclubs
    for (const rclub of rclubs_) {
      const matchingClub = riClubs_.find(riClub => riClub['club'] === rclub.clubname);
      
      if (matchingClub) {
        // Якщо є співпадіння, додаємо rclub з полем "Club Name" з riClubs
        rclub.t_clubname = matchingClub['club'];
        rclub.t_riClubId = matchingClub['clubid'];
        rclub.t_memberCount = matchingClub['Current Member Count'];
        rclub.t_charterDate = matchingClub['Charter Date'];
        //mergedClubs.push(rclub);
      } else {
        // Якщо немає співпадіння, додаємо rclub в інший масив otherClubs
        //mergedClubs.push({ 't_clubname': rclub.clubname });
      }
    }
  
    // Додаємо решту riClubs, які не мають відповідників у rclubs
    for (const riClub of riClubs_) {
      const matchingClub = rclubs_.find(rclub => rclub.clubname === riClub['club']);
      if (!matchingClub) {
        mergedClubs.push(riClub);
      }
    }
  
    return mergedClubs;
}

const RotaryClubsCRUD = ({tags}) => {
    const [rclubs, setRClubs] = useState([]);
    const [riClubs, setRiClubs] = useState([]);
    const [mergeClubs, setMergeClubs] = useState([]);
    const [filteredRclubs, setFilteredRClubs] = useState([]);
    const [open, setOpen] = useState(false);
    const [currentRClub, setCurrentRClub] = useState(generateDefaultObject(rclubColumns));
    const [rowClicked, setRowClicked] = useState(null);
    const [isEditMode, setIsEditMode] = useState(false);
    const [checkedState, setCheckedState] = useState({
        rotary: false,
        rotaract: false,
        interact: false,
      });
    const [checkedStateLang, setCheckedStateLang] = useState({
        UKR: true,
        ENG: false,
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const fetchedRClubs = await fetchRClubs();
                const filteredData = tags ? fetchedRClubs.filter(item => item.tags === tags) : fetchedRClubs;

                const f3 = sortByClubName(filteredData);
                setRClubs(f3);
                
                const filteredClubs1 = filterRClubsByLang(rclubs, checkedStateLang);
                const filteredClubs2 = filterRClubsByCategory(filteredClubs1, checkedState);
                setFilteredRClubs(filteredClubs2);

                const ri_clubs = rotaryClubsFromRiBd();
                setRiClubs(ri_clubs);
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        console.log('filterRClubsByCategory...');
        const filteredClubs1 = filterRClubsByLang(rclubs, checkedStateLang);
        const filteredClubs2 = filterRClubsByCategory(filteredClubs1, checkedState);
        setFilteredRClubs(filteredClubs2);
    }, [checkedState, checkedStateLang]);

    function filterRClubsByLang(rclubs_, checkedStateLang) {
        return rclubs_.filter(club => checkedStateLang[club.clublang]);
    }
    function filterRClubsByCategory(rclubs_, checkedState) {
        return rclubs_.filter(club => checkedState[club.clubcat]);
    }

    function countRotaryClubs(rclubs, rctype) {
        const rotaryClubs = rclubs.filter(club => club.clubcat === rctype);
        return rotaryClubs.length;
    }

    const handleEditRClub = (rclub = generateDefaultObject(rclubColumns)) => {
        setCurrentRClub(rclub);
        setIsEditMode(!!rclub.clubID);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = async () => {
        console.log('=== handleSubmit...');
        try {
            if (isEditMode) {
                await updateRClub(currentRClub.clubID, currentRClub);
            } else {
                await createRClub(currentRClub);
            }
            handleClose();
            const updatedRClubs = await fetchRClubs();
            setRClubs(updatedRClubs);
        } catch (error) {
            console.error(error);
        }
    };

    const handleDelete = async (rclubId) => {
        console.log('=== handleDelete... rclubId='+rclubId);
        try {
            await deleteRClub(rclubId);
            const updatedRClubs = await fetchRClubs();
            setRClubs(updatedRClubs);
        } catch (error) {
            console.error(error);
        }
    };

    const onRowClicked = (row) => {
        setRowClicked(row)
    }

    const handleEditRClubSlils = () => {
        //setOpenSkils(true);
    }

    const handleSkilsClose = () => {
        //setOpenSkils(false);
    };

    const handleChange = (event) => {
        setCheckedState({ ...checkedState, [event.target.name]: event.target.checked });
    };

    const handleChangeLang = (event) => {
        setCheckedStateLang({ ...checkedStateLang, [event.target.name]: event.target.checked });
    };

    const handleTest = async () => {
        if (filteredRclubs) {
            for (const item of filteredRclubs) {
                const tr = await translateToEng(item.clubname);
                console.log(`translateToEng t1=${item.clubname} to eng=${tr}`);
                item.t_clubname = tr;
            }
        }
    };

    const handleMerge = async() => {
        const forMerge = mergeArrays(filteredRclubs, riClubs);
        setMergeClubs(forMerge);
    }

    return (
        <div>
            <Box display="flex" flexDirection="row" pt='8px'>
                
                <Box width="100%">
                <Box>
                    <FormControlLabel
                        control={<Checkbox checked={checkedState.rotary} onChange={handleChange} name="rotary" />}
                        label={"Rotary("+countRotaryClubs(rclubs, "rotary")+")"}
                    />
                    <FormControlLabel
                        control={<Checkbox checked={checkedState.rotaract} onChange={handleChange} name="rotaract" />}
                        label={"Rotaract("+countRotaryClubs(rclubs, "rotaract")+")"}
                    />
                    <FormControlLabel
                        control={<Checkbox checked={checkedState.interact} onChange={handleChange} name="interact" />}
                        label={"Interact("+countRotaryClubs(rclubs, "interact")+")"}
                    />
                </Box>
                    Відібрано клубів: {filteredRclubs ? filteredRclubs.length : 0} з {rclubs ? rclubs.length : 0}
                </Box>

                <Box width="100%">
                <Box>
                    <FormControlLabel
                        control={<Checkbox checked={checkedStateLang.UKR} onChange={handleChangeLang} name="UKR" />}
                        label="Укр"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={checkedStateLang.ENG} onChange={handleChangeLang} name="ENG" />}
                        label="Eng"
                    />
                </Box>
                </Box>

                <Button variant="contained" color="primary" onClick={() => {window.open('/test_RiDb', '_blank')}}>
                    view RI
                </Button>
                <Button variant="contained" color="primary" onClick={() => handleMerge()}>
                    merge
                </Button>
                <Button variant="contained" color="primary" onClick={() => handleTest()}>
                    tr_eng
                </Button>
                <Button variant="contained" color="primary" onClick={() => handleEditRClub()}>
                    Add Rotary Club
                </Button>

                {/*rowClicked &&
                <Button variant="contained" color="primary" onClick={() => handleEditRClubSlils()}>
                Edit Skils
                </Button>*/
                }
            </Box>
            
            <CrudTable data={filteredRclubs} columns={rclubColumns} onEdit={handleEditRClub} onDelete={handleDelete} />
            mergeClubs
            <CrudTable data={mergeClubs} columns={rclubColumns_ri} onEdit={handleEditRClub} onDelete={handleDelete} />

            <AdminDialogCrud
                open={open}
                onClose={handleClose}
                onSubmit={handleSubmit}
                isEditMode={isEditMode}
                columns={rclubColumns}
                currentItem={currentRClub}
                setCurrentItem={setCurrentRClub}
            />

            
            {/*openSkils &&
            <>
                rclubId={rclubs && rowClicked && rclubs[rowClicked] && rclubs[rowClicked].rclub_id}
                <RClubSlilsCRUD rclubId={rclubs[rowClicked].rclub_id} handleSkilsClose={handleSkilsClose} />
            </>
            */}
        </div>
    );
}

export default RotaryClubsCRUD;
