export const getMenuStructure = (local) => {
    return data_eng;
}

const data_eng = {

    navbar: {
        _title: "РеК Україна",
        //logoUrl: "https://res.cloudinary.com/tyko/image/upload/v1704542422/BetaRotarySite/%D0%A1%D0%B0%D0%B9%D1%82%D0%9A%D0%BB%D1%83%D0%B1%D1%96%D0%B2/RC_Cherkasy/share/RC_Cherkasy_trimed_q9pd9g.png",
        logoUrl: 'https://api.rotary.org.ua/uploads/clubsitecreator/rec_ofukraine/logo/ReC_ofUkraine_trimed.png',
        // languages: [
        //     "ua-UA",
        //     "en-US",
        // ],
        // autorization: "true",
        addlogoborder: true,
        withoutd2232url: true, 
        donatebtn: "/rc_eclubofukraine/donate",
        socialsocialNetworks: {
            fb: 'https://www.facebook.com/rotaryeclubukraine',
            youtube: 'https://www.youtube.com/@E-ClubRotaryUkraine',
            email: "rotaryeclubua@gmail.com",
        },
        menu: [
            {
                title: "About Us",
                arr: [
                    { 
                        title: "Overview",
                        lref: '/rc_eclubofukraine/overview',
                    },
                    // { 
                    //     title: "History",
                    //     lref: '/rc_eclubofukraine/history',
                    // },
                    { 
                        title: "Members",
                        lref: '/rc_eclubofukraine/members',
                    // },
                    // { 
                    //     title: "Charitable Fund",
                    //     lref: '/rc_eclubofukraine/charitable',
                    },
                ]
            },
            {
                title: "Projects",
                lref: '/rc_eclubofukraine/projects',
                // arr: [
                //     { 
                //         title: "Projects seaking donations",
                //         lref: '/rc_eclubofukraine/projects',
                //     },
                //     { 
                //         title: "Demining",
                //         lref: '/rc_eclubofukraine/history',
                //     },
                //     { 
                //         title: "Mental Health",
                //         lref: '/rc_eclubofukraine/history',
                //     },
                //     { 
                //         title: "School in Buzova",
                //         lref: '/rc_eclubofukraine/history',
                //     },
                //     { 
                //         title: "Public Health",
                //         lref: '/rc_eclubofukraine/history',
                //     },
                //     { 
                //         title: "Displased People",
                //         lref: '/rc_eclubofukraine/history',
                //     },
                //     { 
                //         title: "Humanitarian",
                //         lref: '/rc_eclubofukraine/history',
                //     },
                // ]
            },
            // {
            //     title: "Community",
            //     arr: [
            //         { 
            //             title: "Rotary International",
            //             lref: '/rc_eclubofukraine/community1',
            //         },
            //         { 
            //             title: "Twin&Sister Clubs",
            //             lref: '/rc_eclubofukraine/community2',
            //         },
            //         { 
            //             title: "District 2232",
            //             lref: '/rc_eclubofukraine/community3',
            //         },
            //         { 
            //             title: "Pennant Collection",
            //             lref: '/rc_eclubofukraine/community4',
            //         },
            //     ]
            // },
            // {
            //     title: "Get Involved",
            //     arr: [
            //         { 
            //             title: "Become a member",
            //             lref: '/rc_eclubofukraine/getinvolved1',
            //         },
            //         { 
            //             title: "Volonteer",
            //             lref: '/rc_eclubofukraine/getinvolved2',
            //         },
            //         { 
            //             title: "Donate",
            //             lref: '/rc_eclubofukraine/getinvolved3',
            //         },
            //         { 
            //             title: "Collaborate",
            //             lref: '/rc_eclubofukraine/getinvolved4',
            //         },
            //     ]
            // }
        ],
    },
    footer: {
        contactPerson_: {
            name: "Priamry contacts:",
            email: "rotaryeclubua@gmail.com",
        },
    }
}
