import { LOCALES } from '../../../i18n/locales';

export const getClubPageStructure = (local) => {

    const translations_ukr = {
        "Клубна сторінка": "Сайт клубу",
        "Про Клуб": "Про Клуб",
        "Події": "Події",
        "Календар": "Календар",
        "Фотогалерея": "Фотогалерея",
        "Контактна особа:": "Контактна особа:",
        "Проєкти": "Проєкти",
        "Ротаракт": "Ротаракт",
        "Донати": "Донати",
        "Новини": "Новини",
        "Співпрацюючі організації": "Співпрацюючі організації",

        "Головна": "Головна",
        "Керівництво": "Керівництво",
        "Правління та посадові особи": "Правління та посадові особи",
        "Дистрикт 2232": "Дистрикт 2232",
    };

    const translations_eng = {
        "Клубна сторінка": "Club Page",
        "Про Клуб": "About the Club",
        "Події": "Events",
        "Календар": "Calendar",
        "Фотогалерея": "Photo Gallery",
        "Контактна особа:": "Primary contact:",
        "Проєкти": "Projects",
        "Ротаракт": "Rotaract",
        "Донати": "Donate",
        "Новини": "News",
        "Співпрацюючі організації": "Cooperating organizations",

        "Головна": "Home",
        "Керівництво": "Leadership",
        "Правління та посадові особи": "Board & Officers",
        "Дистрикт 2232": "District 2232",
    };

    const translations = local === LOCALES.UKRAINIAN ? translations_ukr : translations_eng;

    return {
        navbar: {
            title: translations["Клубна сторінка"],
            //logoUrl: "https://res.cloudinary.com/tyko/image/upload/v1704542422/BetaRotarySite/%D0%A1%D0%B0%D0%B9%D1%82%D0%9A%D0%BB%D1%83%D0%B1%D1%96%D0%B2/RC_Cherkasy/share/RC_Cherkasy_trimed_q9pd9g.png",
            logoUrl: 'https://api.rotary.org.ua/uploads/clubsitecreator/rc_cherkasy/photo/logo/logo3.jpg',
            logoUrl_mobile: 'https://api.rotary.org.ua/uploads/clubsitecreator/rc_cherkasy/photo/logo/logo4.jpg',
            languages: [
                "ua-UA",
                "en-US",
            ],
            autorization: "true",
            menu: [
                {
                    title: translations["Головна"],
                    lref: '/rc_cherkasy',
                },
                {
                    title: translations["Про Клуб"],
                    lref: '/rc_cherkasy/club',
                },
                
                /*
                {
                    title: translations["Про Клуб"],
                    arr: [
                        { 
                            title: translations["Керівництво"],
                            lref: '/rc_cherkasy/leadership',
                        },
                        { 
                            title: translations["Правління та посадові особи"],
                            lref: '/rc_cherkasy/board',
                        },
                    ]
                },*/
                {
                    title: translations["Проєкти"],
                    lref: '/rc_cherkasy/projects',
                },
                {
                    title: translations["Співпрацюючі організації"],
                    lref: '/rc_cherkasy/partners',
                },
                {
                    title: translations["Ротаракт"],
                    lref: '/rc_cherkasy/rotaract',
                },
                {
                    title: translations["Донати"],
                    lref: '/rc_cherkasy/donate',
                },
                {
                    title: translations["Новини"],
                    lref: '/rc_cherkasy/news',
                },
            ],
        },
        footer: {
            contactPerson_: {
                name: translations["Контактна особа:"],
                email: "oncology@2upost.com",
                email2: "paliychuk.oncology@gmail.com",
            },
            // social: {
            //     fb: "fb1",
            //     insta: "insta1",
            //     linkedIn: "linkedIn1",
            // }
        }
    }
}
