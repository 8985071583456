import React, { useEffect, useState } from "react";
import { Box, Container, Grid, Typography, Button, Tooltip  } from '@mui/material'
import '../Pages.css';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { Tabs, Tab } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import {  useMediaQuery, useTheme } from '@mui/material';

import {getAllCommuniques} from '../../api/ts/documents_communique';
import WordTypePage from '../../components/WordTypePage';

const DocumentsFrame = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const matchesMd = useMediaQuery(theme.breakpoints.up('md'));
    const matchesSm = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
    //const maxLength = matchesMd ? 15 : matchesSm ? 50 : r.title.length;

    const [loading, setLoading] = useState(true);
    //const [data, setData] = useState(null);
    const [communiques, setCommuniques] = useState([]);

    useEffect(() => {
        async function fetchData() {
            try {
                const data = await getAllCommuniques();
                setCommuniques(data.reverse());
                setLoading(false);
            } catch (error) {
                console.error("Error fetching data", error);
                setLoading(false);
            }
        }
        fetchData();
    }, []);
  
    const toFileClick = (url) => {
      console.log("toFileClick");
      window.open(url, '_blank');
    }

    return (
        <>

            <Typography variant="h3" className="page-header" align="center"
                    style={{color:'darkblue', paddingBottom: '16px' }} >Комюніке 23-24</Typography>
            <List component="nav">
            
            {loading && <div>Loading...</div>}
            {communiques &&
            communiques.map((row, index) => (
                <>
                <ListItem key={index} 
                        button onClick={() => toFileClick(row.url)}
                        style={{ paddingTop: '4px', paddingBottom: '4px', borderTop: isSmallScreen ? '1px solid #ccc' : 'none'}} >
                    
                    <ListItemText>
                        <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} alignItems="top" color="darkblue">
                            <Box display="flex" alignItems="top">
                            
                            <ListItemIcon>
                                <PictureAsPdfIcon color="primary"fontSize="large" style={{ marginRight: '0px', marginLeft: '10px' }} />
                            </ListItemIcon>
                            <span style={{fontWeight:'bold', whiteSpace: 'nowrap'}}>Комюніке №{row.number}</span> 
                        </Box>
                        <span style={{paddingLeft: '12px'}}>{row.title}</span>
                        </Box>
                        </ListItemText>
                </ListItem>

                <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="flex-start" style={{ paddingLeft: '64px'}}>
                
                {row.urls &&
                    row.urls.map((r, i) => (
                        <Tooltip key={r.url} title={r.title} arrow>
                        <Box display="flex" alignItems="flex-start" justifyContent="flex-start" paddingBottom='8px' marginTop='-8px'>
                            <Button style={{ textTransform: 'none' }} onClick={() => toFileClick(r.url)}>
                                <PictureAsPdfIcon color="primary" fontSize="small" style={{ marginRight: '4px' }} />
                                <Typography variant="subtitle2">
                                    {r.title.length > (matchesMd ? 15 : matchesSm ? 40 : r.title.length) 
                                        ? `${r.title.slice(0, (matchesMd ? 15 : matchesSm ? 40 : r.title.length))}...` 
                                        : r.title}
                                </Typography>
                            </Button>
                        </Box>
                        </Tooltip>
                    ))}
                </Box>
                </>
            ))}
        </List>
        </>
  );
}

export default DocumentsFrame;